var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-select-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("进入项目")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _vm.projectList && _vm.projectList.length > 0
          ? _c(
              "div",
              _vm._l(_vm.projectList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "project-item-wrapper",
                    on: {
                      click: function ($event) {
                        return _vm.selectProjectClick(item)
                      },
                    },
                  },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "project-item-card",
                        attrs: { hoverable: "" },
                      },
                      [
                        _c("img", {
                          staticClass: "project-item-img",
                          attrs: { slot: "cover", src: item.DefaultLogo },
                          slot: "cover",
                        }),
                        _c("a-card-meta", {
                          attrs: { title: item.name, description: item.remark },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _c("a-empty"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }