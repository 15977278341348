

























import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ProjectEntityModel } from '@common-src/entity-model/project-entity';
import ProjectService from '@common-src/service/project';
import { UserStoreModule } from '@common-src/store/modules/user';
import CommonService from '@common-src/service/common';

@Component
export default class ProjectSelectComponent extends BaseComponent {
    projectList: Array<ProjectEntityModel> = null;
    created() {
        ProjectService.getAllProject().then(res => {
            this.projectList = res;
        });
    }
    async selectProjectClick(project: ProjectEntityModel) {
        UserStoreModule.SET_CURRENT_PROJECT(project);
        await CommonService.getUserPermissions2(0);
        this.$router.push(UserStoreModule.RedirectPath);
    }
}

